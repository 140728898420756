import * as React from 'react';

import SEO from '../components/seo';
import MyHealthBookLogo from '!svg-react-loader!../images/vectors/myhealthbook-logo.svg';
import './index.scss';
import ProductCard from '../components/product-card';


const MyHealthBookPage = () => {
  const description = `Get 360° view of your health profile. Stay connected with your doctors. Manage your health records. Get access to your doctor’s dispensary & lab.`;
  const links = [
    { id: 'everyone', title: 'Everyone', linkTo: '/MyHealthBook' }
  ];
  return (
    <div>
      <SEO title="MyHealthBook" />
      <div className="container-fluid main-container">
        <ProductCard
          title={'MyHealthBook'}
          productImage={MyHealthBookLogo}
          description={description}
          links={links}
        />
      </div>
    </div>
  );
};

export default MyHealthBookPage;
